/**
* @file mdm.js
*
* Master file for theme level js.
*/

'use strict';

(function($, Drupal, window, document, undefined) {
  $(document).ready(function() {
    // Init the sidebar navigation.
    $('.button-collapse').sideNav();
    // Init the contact sidebar.
    $('.button-contact').sideNav({
      menuWidth: 450,
      edge: 'right'
    });
    // Init all modals.
    $('.modal').modal({
      startingTop: '2%',
      endingTop: '7%'
    });
    // Init all lightboxes.
    $('.materialboxed').materialbox();
    // Init all sliders.
    $('.slider').slider();
    // Init all select inputs.
    $('select').material_select();
    // Init all smooth scrollers.
    $('.scrollspy').scrollSpy();
    // Init all drop downs.
    $('.dropdown-button').dropdown({
        hover: true,
        constrainWidth: false,
        belowOrigin: true
      }
    );
    // Stop hashbang links from firing.
    $('a[href^="#!"]').click(function(e){
      e.preventDefault();
    });
    // Bust out mega menus.
    $('.dropdown-mega').appendTo('#navigation');
    // Init product carousels.
    $('.product-carousel').owlCarousel({
      responsive: {
        // breakpoint from 0 up
        0: {
          items: 1,
          lazyLoad: true,
          dots: true,
          autoplay: true
        },
        // breakpoint from 480 up
        601: {
          items: 2,
          lazyLoad: true,
          margin: 25,
          dots: true,
          autoplay: true
        },
        // breakpoint from 768 up
        993: {
          items: 4,
          lazyLoad: true,
          margin: 25,
          dots: true,
          autoplay: true
        }
      }
    });
    // Init card carousels.
    $('.card-carousel').owlCarousel({
      responsive: {
        // breakpoint from 0 up
        0: {
          items: 1,
          lazyLoad: true,
          margin: 25,
          stagePadding: 0,
          dots: true,
          autoplay: true
        },
        // breakpoint from 480 up
        601: {
          items: 2,
          lazyLoad: true,
          margin: 25,
          stagePadding: 20,
          dots: true,
          autoplay: true
        },
        // breakpoint from 768 up
        993: {
          items: 4,
          lazyLoad: true,
          margin: 25,
          stagePadding: 20,
          dots: true,
          autoplay: true
        }
      }
    });
    // Init cvp carousels.
    $('.cvp-carousel').owlCarousel({
      responsive: {
        // breakpoint from 0 up
        0: {
          items: 2,
          lazyLoad: true,
          margin: 25,
          stagePadding: 20,
          dots: true,
          autoplay: true
        },
        // breakpoint from 768 up
        993: {
          items: 4,
          lazyLoad: true,
          margin: 25,
          stagePadding: 20,
          dots: true,
          autoplay: true
        }
      }
    });
    // Init all slideshows.
    $('.md-slideshow').owlCarousel({
      responsive: {
        0: {
          autoWidth: true,
          center: true,
          loop: true,
          margin: 0,
          autoplay: true,
          nav: true,
          navElement: 'div',
          dots: false,
          items: 1
        },
        993: {
          autoWidth: true,
          center: true,
          loop: true,
          margin: 0,
          autoplay: true,
          nav: true,
          navElement: 'div',
          dots: false,
          items: 2
        }
      }
    });
    // Init headroom.js
    var navigation = document.querySelector('#navigation');
    new Headroom(navigation, {
      tolerance: {
        down: 2,
        up: 5
      },
      offset: 128
    }).init();
  });
})(jQuery, Drupal, this, this.document);
