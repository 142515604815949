/**
 * @file
 * md.financeapplication.js
 */

'use strict';

(function($, Drupal, window, document, undefined) {
  Drupal.behaviors.motordealerFinanceApplication = {
    attach: function(context, settings) {
      var $applicationButton = $('.motordealerFinanceApply'),
          financeURL = 'https://www.yamaha-motor.com.au/ymf-application';

      $applicationButton.bind('click', function(e) {
        e.preventDefault();

        var $this = $(this),
            src = financeURL + '?src=' + $this.data('dealername').toString().replace(/[^0-9a-zA-Z\s]/g, '');

        // Open Yamaha finance application in a new window.
        window.open(src, '_blank');
      });
    }
  };
})(jQuery, Drupal, this, this.document);
